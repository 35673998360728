import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  Section,
  Container,
  Row,
  Column,
  Card,
  CardBody,
} from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { Marker } from '@react-google-maps/api';
import { toLatLng, Map } from '../components/map/Map';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import marker from '../images/marker.svg';
import { SocialLink } from '../components/SocialLink';
import { Text } from '../components/Text';
import { Tag } from '../components/Tag';
import { RowContainer } from '../components/RowContainer';
import { OrganizationModel } from '@nimles/models/lib/organization-management';
import { SiteMetadata } from '../types';
import { getImageUrl } from '../utils';

const BannerSection = styled(Section)`
  min-height: 50vh;
  padding: 0;
  color: #fff;

  h1,
  h2 {
    color: #fff;
    margin-bottom: 0;
    text-align: center;
  }
`;

const Logo = styled.img`
  margin-bottom: 30px;
  width: 120px;
`;

const Organization = ({ pageContext, location }) => {
  const {
    organization,
    site,
  }: {
    organization: OrganizationModel;
    site: { siteMetadata: SiteMetadata };
  } = pageContext;
  const {
    name,
    summary,
    description,
    logoId,
    bannerId,
    website,
    tags,
    phoneNumbers,
    emails,
    address: { street, postalCode, city },
  } = organization;
  const [, setPanorama] = useState(false);

  useEffect(() => {
    if (!bannerId) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/streetview/metadata?location=${street},${postalCode}%20${city}&key=AIzaSyBhf97-rchmVMsAUBw5Bx-Xy4poatMAc0s`
        )
        .then((r) => {
          if (r?.data?.status === 'OK') {
            setPanorama(true);
          }
        });
    }
  }, []);

  const banner = getImageUrl(bannerId, 1920);

  const meta = {
    title: 'Quotes - ' + name,
    description: summary,
    image: banner,
    keywords: tags?.join(', '),
  };

  return (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      siteMetadata={site.siteMetadata}
    >
      <BannerSection padding="100px 0 0" backgroundImage={banner}>
        <Section backgroundColor="rgba(0,0,0,0.6)" flex>
          <Container flex justify="center">
            <h1>{name}</h1>
            <h2>{city}</h2>
          </Container>
          <Container>
            <Row wrap="wrap" justify="space-between" align="flex-end">
              <Column>
                {phoneNumbers ? (
                  <Row align="center">
                    <Column>
                      <FontAwesomeIcon icon={faPhone} size="lg" />
                    </Column>
                    {phoneNumbers?.map((phone) => (
                      <SocialLink
                        key={phone}
                        href={`tel:${phone}`}
                        target="_blank"
                      >
                        <Column>{phone}</Column>
                      </SocialLink>
                    ))}
                  </Row>
                ) : null}
                {emails ? (
                  <Row align="center">
                    <Column>
                      <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    </Column>
                    {emails.map((email) => (
                      <SocialLink
                        key={email}
                        href={`mailto:${email}`}
                        target="_blank"
                      >
                        <Column>{email}</Column>
                      </SocialLink>
                    ))}
                  </Row>
                ) : null}
              </Column>

              <Column>
                <Row justify="center" wrap="wrap" margin="0 -10px">
                  {website ? (
                    <Column padding="0 10px">
                      <SocialLink
                        href={
                          website.indexOf('http') === 0
                            ? website
                            : `http://${website}`
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faHome} size="lg" />
                      </SocialLink>
                    </Column>
                  ) : null}
                  {/* {socialArray.map((s) => {
                    const social = socialLinks?.find(
                      (item) => item.key === s.name
                    );

                    return social ? (
                      <Column key={social.key} padding="0 10px">
                        <SocialLink href={social.value} target="_blank">
                          <FontAwesomeIcon icon={s.icon} size="lg" />
                        </SocialLink>
                      </Column>
                    ) : null;
                  })} */}
                </Row>
              </Column>
            </Row>
          </Container>
        </Section>
      </BannerSection>
      <Section>
        <Container>
          <RowContainer>
            <Row wrap="wrap">
              <Column xs={100} md={50} lg={60} justify="center">
                <Text>
                  {logoId ? (
                    <Row justify="center">
                      <Column>
                        <Logo src={getImageUrl(logoId, 480)} alt={name} />
                      </Column>
                    </Row>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Text>
              </Column>
              <Column xs={100} md={50} lg={40} justify="center">
                {location ? (
                  <Card flex>
                    <CardBody>
                      <Map center={organization.location}>
                        <Marker
                          position={toLatLng(organization.location)}
                          icon={marker}
                        />
                      </Map>
                    </CardBody>
                    <CardBody>
                      <Row justify="center">
                        <Column align="center">
                          <div>{street}</div>
                          <div>
                            {postalCode} {city}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                ) : null}
              </Column>
            </Row>
          </RowContainer>
          <Row justify="center" wrap="wrap" margin="0 -10px">
            {tags?.sort().map((tag) => (
              <Column>
                <Tag>{tag}</Tag>
              </Column>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default Organization;
