import styled from '@emotion/styled';

export const RowContainer = styled.div`
  position: relative;
  margin: 10px 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 35px;
    right: -10px;
    bottom: 45px;
    left: -10px;
    background: rgba(72, 93, 60, 0.1);
    @media (min-width: ${({ theme }) => theme?.thresholds?.md}px) {
      top: 35px;
      right: -100px;
      left: -100px;
    }
  }
`;
